
import { Component, Prop } from "vue-property-decorator";
import { BaseForm } from "./BaseForm";
import Password from "vue-password-strength-meter";

@Component({
	components: {
		Password,
	},
})
export default class ResetPasswordForm extends BaseForm {
	@Prop({ default: {} })
	public metadata!: Record<string, any>;

	public labels: Record<string, string> = {
		password: "New Password",
		confirmPassword: "Confirm Password",
	};

	public model: Record<string, any> = {
		password: null,
		confirmPassword: null,
	};

	public async send() {
		const response = await this.$api.post(this.action, { ...this.metadata, password: this.model.password });
		if (!response.ok) {
			return this.handleError(response);
		}
		this.$emit("done", response.body);
	}
}
